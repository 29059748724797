import React from "react"
import { Link } from "gatsby"
import {
  Form,
  FormTextarea,
  FormInput,
  FormSubmit,
  makeRem,
  Spinner,
  Text,
} from "@thedgbrt/logology-ui-components"
import useForm from "./useFormCustom"

const FormCustom = props => {
  const {
    error,
    inputs,
    inputErrors,
    status,
    handleSubmit,
    handleInputChange,
  } = useForm(props)

  let inputEmailRef = React.createRef()
  let input1Ref = React.createRef()
  let input2Ref = React.createRef()
  let input3Ref = React.createRef()
  let input4Ref = React.createRef()

  if (status.state === "loading") return <Spinner align="left" />

  if (status.state === "success")
    return (
      <Text lead italic>
        We received your request and will get back to you soon.{" "}
        <Link to="/myaccount/proposals/">Go back to proposals</Link>.
      </Text>
    )

  return (
    <div style={{ paddingBottom: makeRem(6), paddingTop: makeRem(6) }}>
      {error.exists ? (
        <Text style={{ color: "red", marginTop: makeRem(0) }}>
          {error.message}
        </Text>
      ) : null}
      <Form left>
        <FormTextarea
          label="Do you have a specific symbol idea in mind?"
          name="input1"
          ref={input1Ref}
          error={inputErrors.input1}
          onChange={e => handleInputChange(e, input1Ref)}
        >
          {inputs.input1}
        </FormTextarea>
        <FormTextarea
          label="What is your product / service?"
          required
          name="input2"
          ref={input2Ref}
          error={inputErrors.input2}
          onChange={e => handleInputChange(e, input2Ref)}
        >
          {inputs.input2}
        </FormTextarea>
        <FormTextarea
          label="Who is your target customer?"
          required
          name="input3"
          ref={input3Ref}
          error={inputErrors.input3}
          onChange={e => handleInputChange(e, input3Ref)}
        >
          {inputs.input3}
        </FormTextarea>
        <FormTextarea
          label="Anything else we might need to know?"
          name="input4"
          ref={input4Ref}
          error={inputErrors.input4}
          onChange={e => handleInputChange(e, input4Ref)}
        >
          {inputs.input4}
        </FormTextarea>
        <FormInput
          label="Where can we reach you?"
          required
          type="email"
          placeholder="My email address"
          name="inputEmail"
          ref={inputEmailRef}
          error={inputErrors.inputEmail}
          onChange={e => handleInputChange(e, inputEmailRef)}
          value={inputs.inputEmail}
        />
        <FormSubmit
          text="Send request"
          onClick={e =>
            handleSubmit(e, [
              inputEmailRef,
              input1Ref,
              input2Ref,
              input3Ref,
              input4Ref,
            ])
          }
        />
      </Form>
    </div>
  )
}

export default FormCustom
