import React from "react"
import { connect } from "react-redux"
import {
  Grid3TopBar,
  GridBox,
  GridContainer,
  Standalone,
  Text,
  Title,
  makeRem,
} from "@thedgbrt/logology-ui-components"
import { Link } from "gatsby"
import FormCustom from "./FormCustom"

const RequestCustom = props => {
  return (
    <>
      <Grid3TopBar>
        <Link to={`/myaccount/proposals/`}>← back</Link>
      </Grid3TopBar>
      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 5]]} type={4}>
          <Standalone>
            <Title level={3}>Custom symbol request</Title>
          </Standalone>
        </GridBox>
      </GridContainer>

      <GridContainer type={4}>
        <GridBox
          colSpans={[[2, 3], [2, 2], [2, 2]]}
          type={4}
          className="hide_mobile"
        >
          <Text standalone lead style={{ paddingTop: makeRem(4) }}>
            <b>How it works</b>
          </Text>
        </GridBox>

        <GridBox colSpans={[[2, 4], [3, 4], [3, 5]]} type={4}>
          <Standalone>
            <Text
              lead
              className="hide_desktop hide_tablet"
              style={{ marginBottom: makeRem(-9) }}
            >
              <strong>How it works</strong>
            </Text>
            <Text lead italic>
              <p>
                We can create custom symbols and give you{" "}
                <b>full exclusive rights</b> for it. Please give us as much
                details as possible and we’ll get back to you within 2 business
                days with a detailed quote.
              </p>

              <p>
                Price is <b>$799 for 3 design proposals</b> to choose from.
                Fully refundable if you don’t like any of the results.
              </p>
            </Text>
          </Standalone>
        </GridBox>
      </GridContainer>

      <GridContainer type={4}>
        <GridBox
          colSpans={[[2, 3], [2, 2], [2, 2]]}
          type={4}
          className="hide_mobile"
        >
          <Text standalone lead style={{ paddingTop: makeRem(4) }}>
            <b>My request</b>
          </Text>
        </GridBox>

        <GridBox colSpans={[[2, 4], [3, 4], [3, 5]]} type={4} withBorder>
          <Standalone>
            <Text
              lead
              className="hide_desktop hide_tablet"
              style={{ marginBottom: makeRem(5) }}
            >
              <strong>My request</strong>
            </Text>
            <FormCustom {...props} />
          </Standalone>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ questionnaire, user }) => ({
    email: user.email,
    activityId: questionnaire.activityId,
    activityName: questionnaire.activityName,
    startupName: questionnaire.startupName,
    personaId: user.profile.personaId,
    personalityScores: user.profile.personalityScores,
  }),
  {}
)(RequestCustom)
