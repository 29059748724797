import { useState } from "react"
import API from "@aws-amplify/api"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"
import { validateInput, validateInputs } from "../../helpers/validation"

const useForm = ({
  email,
  activityId,
  activityName,
  personaId,
  personalityScores,
  startupName,
}) => {
  const [status, setStatus] = useState({
    state: "",
  })

  const [hasValidatedOnce, setHasValidatedOnce] = useState(false)

  const [error, setError] = useState({
    exists: false,
    message: "",
  })

  const [inputErrors, setInputErrors] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    email: "",
  })

  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    inputEmail: email,
  })

  const handleInputChange = (event, ref) => {
    event.persist()

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))

    // if we already validated the form once (after first submit try),
    // update validation results in real time
    if (hasValidatedOnce) validateInput(ref, setInputErrors)
  }

  const handleSubmit = async (event, inputRefs) => {
    if (event) {
      event.preventDefault()
      setHasValidatedOnce(true)

      // validate each field individually
      if (validateInputs(inputRefs, setInputErrors) !== true) return

      let params = {
        email: inputs.inputEmail,
        fieldSymbol: inputs.input1,
        fieldProduct: inputs.input2,
        fieldCustomer: inputs.input3,
        fieldOther: inputs.input4,
        activityId,
        activityName,
        personaId,
        personalityScores: JSON.stringify(personalityScores),
        startupName,
        requestType: "custom",
      }

      setError({ exists: false, message: "" })
      setStatus({ state: "loading" })

      API.get("requestcustom", "/request-custom", {
        queryStringParameters: params,
      })
        .then(res => {
          setStatus({ state: "success" })
          trackSegmentEvent("track", "Request custom design", {
            type: "custom",
          })
          trackAmplitude("Request custom design", { type: "custom" })
        })
        .catch(err => {
          console.log(err)
          setStatus({ state: "error" })
          setError({
            exists: true,
            message:
              "There was an error processing your request. Please try again or contact us at support@logology.co.",
          })
        })
    }
  }

  return {
    error,
    handleSubmit,
    handleInputChange,
    inputs,
    inputErrors,
    status,
  }
}

export default useForm
